import { Box, Grid, Link, Stack, Typography } from "@mui/material";

import { useEffect } from "react";
import { FrameWrapper } from "../components/FrameWrapper";
import { NucleusButton } from "../components/NucleusButton";

function debounce<T extends (...args: any[]) => void>(
  fn: any,
  wait: number,
  immediate: boolean
) {
  let timeout: string | number | NodeJS.Timeout | undefined;
  return function <U>(this: U, ...args: Parameters<typeof fn>) {
    const context = this;
    const later = function () {
      timeout = undefined;
      if (!immediate) fn.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) fn.apply(context, args);
  };
}

function isInView(el: Element) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  // Only completely visible elements return true:
  //let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  let isVisible = elemTop <= window.innerHeight;
  // if (direction === 'up') {
  if (rect.height > window.innerHeight && elemTop <= window.innerHeight) {
    isVisible = true;
  }
  // Partially visible elements return true:
  // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

//check to see if any animation containers are currently in view
const checkInView = debounce(
  function (animation_elements: Element[]) {
    //iterate through elements to see if its in view
    for (let i = 0; i < animation_elements.length; i++) {
      const element = animation_elements[i];
      if (!element.classList.contains("in-view")) {
        if (isInView(element)) {
          element.classList.add("in-view");
        }
      }
    }
  },
  150,
  false
);

export default function EnterpriseView() {
  useEffect(() => {
    const animation_elements = document.querySelectorAll(
      ".animated:not(.in-view)"
    );
    checkInView(animation_elements);
    window.addEventListener("scroll", (e) => checkInView(animation_elements));
  }, []);

  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container margin={0} direction="column">
        <Stack
          component="section"
          direction="column"
          className="dark video"
          padding={2}
        >
          <video
            width="auto"
            height="auto"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          >
            <source
              src={require("../assets/videos/nucleus-background-short.mp4")}
              type="video/mp4"
            />
          </video>
          <Box className="wrapper">
            <Box className="block-left">
              <Typography variant="h1">The Cloud Based AI System</Typography>
              <Typography variant="h2">
                The Voice ggdgdggd at the Center of your Business.
              </Typography>
              <Typography variant="body1">
                <b>
                  Empower your enterprise with NucleusAI, a cutting-edge
                  communication platform leveraging Artificial Intelligence to
                  streamline operations, boost productivity, and drive growth.
                </b>{" "}
                Whether you need to automate customer service, enhance sales, or
                transform internal communication and data reporting, our
                scalable and customizable AI solutions are tailored to your
                business needs.
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack component="section" className="left">
          <Box className="row">
            <article className="box-stretch">
              <h1>Why Choose Nucleus AI</h1>
              <h2>Empower Your Enterprise</h2>
              <div className="text">
                <p>
                  <b>Custom AI Solutions:</b> Tailored to meet specific
                  enterprise needs, our AI learns from your data, adapts to your
                  workflows, and delivers intelligent communication at scale.
                </p>
                <p>
                  <b>Streamlined Communication:</b> Optimize across phone,
                  email, and SMS with advanced call routing, real-time voice
                  analysis, and automated follow-ups, freeing your team for
                  high-value tasks.
                </p>
                <p>
                  <b>Enterprise Integration:</b> Seamlessly integrates with CRM,
                  ERP, and other business systems, enabling unified
                  communication management from a single platform.
                </p>
                <p>
                  <b>Scalability & Flexibility:</b> Designed to evolve with your
                  business, NucleusAI supports everything from small teams to
                  global enterprises.
                </p>
                <p>
                  <b>Comprehensive AI Consulting:</b> Expert guidance from
                  strategy development to implementation, ensuring AI adoption
                  aligns with your business goals.
                </p>
              </div>
            </article>
            <article className="box-stretch">
              <img
                src="/images/use-case-handyman.png"
                className="full"
                alt=""
              />
            </article>
          </Box>
        </Stack>
        <Stack component="section" className="right">
          <Box className="row">
            <article className="box-stretch">
              <img
                src="/images/use-case-small-team.png"
                className="full"
                alt=""
              />
            </article>
            <article className="box-stretch">
              <div>
                <h1>Key Features</h1>
                <h2>Leverage AI</h2>
                <div className="text">
                  <p>
                    <b>Advanced Call Routing:</b> AI-driven call routing reduces
                    wait times, directing customers to the right team members
                    for better service.
                  </p>
                  <p>
                    <b>AI-Powered Customer Service:</b> Virtual assistants
                    handle common queries, provide 24/7 support, and automate
                    repetitive tasks.
                  </p>
                  <p>
                    <b>Sales Automation:</b> Automate sales processes, from lead
                    generation to follow-ups, helping close deals faster with
                    real-time insights.
                  </p>
                  <p>
                    <b>Omnichannel Communication:</b> Unified management of
                    phone, SMS, email, and social media ensures a consistent
                    customer experience.
                  </p>
                  <p>
                    <b>Real-Time Analytics:</b> Track performance, customer
                    interactions, and engagement to continuously optimize your
                    strategy.
                  </p>
                  <p>
                    <b>Security & Compliance:</b> Enterprise-grade encryption
                    and compliance with industry standards to safeguard data.
                  </p>
                </div>
              </div>
            </article>
          </Box>
        </Stack>

        <Stack
          component="section"
          direction="column"
          sx={{ backgroundColor: "#ffffff" }}
          padding={2}
        >
          <Box className="text">
            <Typography variant="h3">Our AI Consulting Services</Typography>
            <Typography variant="body1">
              NucleusAI offers more than just technology; we provide
              comprehensive AI consulting services tailored to enterprise
              clients. Our consulting services include:
            </Typography>
            <p>
              {" "}
              <ul>
                <li>
                  <b>AI Strategy Development:</b> We help you define and
                  implement a robust AI strategy that aligns with your business
                  objectives.
                </li>
              </ul>
            </p>
            <p>
              {" "}
              <ul>
                <li>
                  <b>Custom AI Model Development:</b> We build AI models that
                  are trained specifically for your business, ensuring that they
                  meet your unique communication needs.
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  <b>Implementation & Optimization:</b> From deployment to
                  ongoing optimization, our team ensures that your AI systems
                  are performing at their highest potential, driving tangible
                  results for your enterprise.
                </li>
              </ul>
            </p>
          </Box>
        </Stack>

        <Stack
          component="section"
          direction="column"
          sx={{ backgroundColor: "#f8fafe" }}
          padding={2}
        >
          <Box className="text">
            <Typography variant="h3">Get Started with NucleusAI</Typography>
            <Typography variant="body1">
              Take the first step in transforming your enterprise’s
              communication systems with NucleusAI. Contact our team today at{" "}
              <a href="mailto://enterprise@nucleus.com">
                enterprise@nucleus.com
              </a>{" "}
              to schedule a consultation, and learn more about how NucleusAI can
              help your enterprise thrive in the AI age.
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Box>
  );
}
